import { Injectable } from "@angular/core";
import { User } from "../user.model";

@Injectable({
    providedIn: "root",
})
export class UserAdapter {

    /**
     * Permet d'adapter les données d'une DTO à un utilisateur
     * @param item DTO
     * @returns Utilisateur
     */
    public adapt(item: any): User {
        return new User(
            item.id,
            item.lastname,
            item.firstname,
            item.email,
            item.role,
            item.linkedWithTerminal,
            item.isLoggedIn,
            item.dates
        );
    }
}
