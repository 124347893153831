<nb-layout>
    <nb-layout-header fixed>
        <div class="header">
            <div class="header-container">
                <div class="logo-container">
                    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
                        <nb-icon icon="menu-2-outline"></nb-icon>
                    </a>
                    <a class="logo" href="/">
                        <img src="/assets/svg/wisee-mini.svg" height="50px">
                    </a>
                </div>
            </div>
            <div class="header-container">
                {{ user?.getFullName() }}
                <nb-actions size="small">
                    <nb-action class="control-item" icon="log-out" (click)="logoutAction()"></nb-action>
                </nb-actions>
            </div>
        </div>
    </nb-layout-header>

    <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <nb-menu [items]="menu"></nb-menu>
    </nb-sidebar>

    <nb-layout-column>
        <router-outlet></router-outlet>
    </nb-layout-column>

    <nb-layout-footer fixed>
        <!-- Insert footer here -->
    </nb-layout-footer>
</nb-layout>