import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticateGuard  {

    constructor(
        private authService: AuthService,
        private router: Router,
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {

        if (!this.authService.isLoggedIn()) {
            return this.router.navigateByUrl('/auth');
        } else {
            return true;
        }
    }
}