import { Role } from "./enum/role.enum";
import { IDate } from "./interface/date.interface";

export class User {

    public id: number;
    public lastname: string;
    public firstname: string;
    public email: string;
    public role: Role;
    public linked: boolean;
    public logged: boolean;

    private readonly _dates: IDate;

    constructor(
        id: number,
        lastname: string,
        firstname: string,
        email: string,
        role: Role,
        linked: boolean,
        logged: boolean,
        dates: IDate
    ) {

        this.id = id;
        this.lastname = lastname;
        this.firstname = firstname;
        this.email = email;
        this.role = role;
        this.linked = linked;
        this.logged = logged;
        this._dates = dates;
    }

    public getFullName(): string {
        return `${this.firstname[0].toUpperCase() + this.firstname.slice(1)} ${this.lastname[0].toUpperCase() + this.lastname.slice(1)}`;
    }
}
