import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageComponent } from './pages/page.component';
import { AuthenticateGuard } from './guards/authenticate.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: '',
    component: PageComponent,
    canActivate: [AuthenticateGuard],
    children: [
      {
        path: 'dashboard',
        canActivate: [],
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'user',
        canActivate: [],
        loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule),
      },
      {
        path: 'default-settings',
        canActivate: [],
        loadChildren: () => import('./pages/default-settings/default-settings.module').then(m => m.DefaultSettingsModule),
      },
      {
        path: 'sign',
        canActivate: [],
        loadChildren: () => import('./pages/sign/sign.module').then(m => m.SignModule),
      },
      {
        path: 'terminal',
        canActivate: [],
        loadChildren: () => import('./pages/terminal/terminal.module').then(m => m.TerminalModule),
      },
      {
        path: 'store',
        canActivate: [],
        loadChildren: () => import('./pages/store/store.module').then(m => m.StoreModule),
      },
      {
        path: 'cartridge',
        canActivate: [],
        loadChildren: () => import('./pages/cartridge/cartridge.module').then(m => m.CartridgeModule),
      },
      {
        path: 'casino-response',
        canActivate: [],
        loadChildren: () => import('./pages/casino-response/casino-response.module').then(m => m.CasinoResponseModule),
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
